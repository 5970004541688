import React, { ReactElement } from "react"

function Rates(): ReactElement {
  return (
    <section id="rates" className="pt-4 pb-8 bg-black">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h3 className="text-3xl leading-8 font-extrabold tracking-tight text-gray-100 sm:text-4xl sm:leading-10">
            Rates
          </h3>
        </div>
        <div className="mt-4 max-w-2xl text-xl leading-7 text-gray-200 lg:mx-auto">
          <p>
            I offer in-home service, or remote support: $75 per hour not
            including parts. Pick-up service is also available.
          </p>
          <p className="mt-4 mb-2">
            Special flat rates are available for the following services:
          </p>

          <ul>
            <li className="flex justify-between">
              <span>Reimage/Rebuild Windows:</span>
              <span>$175.00 Flat Rate</span>
            </li>
            <li className="flex justify-between">
              <span>Build a PC from Scratch:</span>
              <span>$275.00 Flat Rate</span>
            </li>
            <li className="flex justify-between">
              <span>Virus Cleanup/PC Cleanup:</span>
              <span>$100.00 Flat Rate</span>
            </li>
            <li>Hourly Rate: $75.00</li>
            <li>Hourly Senior Rate: $60.00</li>
          </ul>

          <p className="py-2 mt-4 text-outline">Free Estimates!</p>
          <p>
            I will provide you with a free estimate for computer repair before
            starting any work. We will not exceed that estimate without your
            permission.
          </p>
        </div>
      </div>
    </section>
  )
}

export default Rates
