import React, { ReactElement } from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
// @ts-ignore
import Remote from "../svgs/remote.svg"

function RemoteSupport(): ReactElement {
  const data = useStaticQuery(graphql`
    query RemoteQuery {
      file(relativePath: { eq: "ConnectWise-RGB-Vert-Reverse.png" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)
  return (
    <section id="remotesupport" className="bg-black pt-4 pb-4 px-4">
      <div className="lg:text-center">
        <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-100 sm:text-4xl sm:leading-10">
          Remote Support
        </h3>
        <Remote className="w-56 mx-auto mt-10" />
      </div>
      <div className="mt-4 max-w-2xl text-xl leading-7 text-gray-200 lg:mx-auto">
        <p>
          I offer remote support for Macs and PCs. Please contact me and
          schedule an appointment.
        </p>
        <div className="max-w-2xl text-xl mt-2 leading-7 text-gray-200 lg:mx-auto text-center">
          <a href="https://nelsonscomputerhelp.screenconnect.com/">
            <div className="border-4 border-blue-500 shadow-2xl rounded-xl w-72 mx-auto">
              <button className="w-56">
                <Img
                  fluid={data.file.childImageSharp.fluid}
                  alt="Nelson's Remote Support Logo"
                />
              </button>
              <p className="text-white">Nelson‘s Remote Support</p>
            </div>
          </a>
        </div>
      </div>
    </section>
  )
}

export default RemoteSupport
