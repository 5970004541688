import React, { ReactElement } from "react"
import Carousel from "@brainhubeu/react-carousel"
import "@brainhubeu/react-carousel/lib/style.css"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"

function Testimonials(): ReactElement {
  return (
    <section id="testimonials" className="bg-green pt-4 pb-4">
      <div className="lg:text-center">
        <h3 className="mt-2 px-4 text-3xl leading-8 font-extrabold tracking-tight text-gray-100 sm:text-4xl sm:leading-10">
          Testimonials
        </h3>
      </div>
      <Carousel
        arrowLeft={
          <IoIosArrowBack className="text-3xl sm:text-5xl text-white" />
        }
        arrowRight={
          <IoIosArrowForward className="text-3xl sm:text-5xl text-white" />
        }
        addArrowClickHandler
        infinite
        centered
        animationSpeed={2000}
      >
        <div className="sm:block sm:max-w-screen-xl mx-auto mt-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto text-left sm:text-center text-2xl leading-7 text-gray-200">
            <p>
              “To fellow compatriots with Personal Computer (PC) issues. Yes
              that small device that packs an enormous capacity of computer
              power can be a pain at times. I have lived in the world of
              computing for years and I still get discombobulated by the
              complexity of the personal system. I was introduced to Nelson when
              I had my first issue with a virus. Through his outstanding service
              my issue and subsequent issues have always been resolved in a
              timely fashion. Nelson is the absolute professional when it comes
              to the server and PC world. He is very timely in responding to
              your call for assistance, he shows up on time, resolves the
              problem in a timely fashion responding to ones anxiety. He has a
              positive attitude when assisting you and a smile on his face for
              confidence. His demeanor is one of discussing the issue and
              recommending a course of action in a manner understandable to a
              layman. Nelson is extremely knowledgeable in the technology of
              diverse hardware and software platforms. He is current in various
              security technologies and the various products that support those
              technologies. Nelson, during his time for problem resolution, will
              also make recommendations on how to enhance and better protect
              your system from outside access. He will make suggestions for
              performance enhancements to allow your system to perform better: I
              value his recommendations and I have seen faster system response.
              His recommendations are sound and he is very reasonable with his
              cost structure. Follow-on dialogues are not an issue with Nelson,
              calls and e-mails to him are returned in a timely manner. If I was
              not retired and still managing in the Information Technology
              environment, I would have Nelson on my staff. Please give him a
              call on your next PC issue, you can’t go wrong.”
            </p>
          </div>
          <footer className="mt-2">
            <div className="md:flex md:items-center md:justify-center">
              <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                <div className="text-base leading-6 font-medium text-gray-200">
                  Andy D.
                </div>
              </div>
            </div>
          </footer>
        </div>
        <div className="max-w-screen-xl mx-auto mt-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto text-left sm:text-center text-2xl leading-7 text-gray-200">
            <p>
              “Nelson found and provided guidance to correct a big bottleneck in
              a server hardware configuration before it went into production.
              This saved my company from making a significant mistake. Nelson is
              professional, candid and honest and works efficiently and with
              precision. Highly recommended!”
            </p>
          </div>
          <footer className="mt-2">
            <div className="md:flex md:items-center md:justify-center">
              <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                <div className="text-base leading-6 font-medium text-gray-200">
                  Mark C.
                </div>
              </div>
            </div>
          </footer>
        </div>
        <div className="max-w-screen-xl mx-auto sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto text-left sm:text-center text-2xl leading-7 text-gray-200">
            <p>
              “I have used Nelson for awhile now. He manages to repair all the
              things I do to my computer. Leaves it better than new. Nelson went
              through a new HP laptop my wife bought that was running so poorly
              I wanted to return it. It has been wonderful ever since. I like
              using Nelson for his good work ethic as well as his devotion to a
              good job. He fees are reasonable and fair. Best of all he comes to
              me.”
            </p>
          </div>
          <footer className="mt-2">
            <div className="md:flex md:items-center md:justify-center">
              <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                <div className="text-base leading-6 font-medium text-gray-200">
                  Dominique M.
                </div>
              </div>
            </div>
          </footer>
        </div>
        <div className="max-w-screen-xl mx-auto mt-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto text-left sm:text-center text-2xl leading-7 text-gray-200">
            <p>
              “Nelson’s amazing knowledge base is indispensable for maintaining
              my IT life. His communication is clear, he’s a trustworthy
              pleasure to work with, and now, he can even offer services
              remotely when I’m an ocean away.”
            </p>
          </div>
          <footer className="mt-2">
            <div className="md:flex md:items-center md:justify-center">
              <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                <div className="text-base leading-6 font-medium text-gray-200">
                  Phyllis R.
                </div>
              </div>
            </div>
          </footer>
        </div>
        <div className="max-w-screen-xl mx-auto mt-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto text-left sm:text-center text-2xl leading-7 text-gray-200">
            <p>
              “My friend Nelson is my IT guy. Comes to the house, goes computer
              shopping with me, doesn’t laugh at me when I ask dumb questions. I
              could go on and on.”
            </p>
          </div>
          <footer className="mt-2">
            <div className="md:flex md:items-center md:justify-center">
              <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                <div className="text-base leading-6 font-medium text-gray-200">
                  Ronnie T.
                </div>
              </div>
            </div>
          </footer>
        </div>
        <div className="w-full max-w-screen-xl mx-auto mt-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto text-left sm:text-center text-2xl leading-7 text-gray-200">
            <p>
              “Have been shopping local in the Seacoast for locally owned
              computer services for 20 years. Ran across Nelson Ashberger in
              January. What a unique find! I dropped off my desktop to him and
              within 24 hours he had it working. Problems solved and computer
              has never worked so well. Nelson is in a league of his own for
              friendly, professional and straightforward solutions. My
              recommendation - www.nelsonscomputerhelp.com”
            </p>
          </div>
          <footer className="mt-2">
            <div className="md:flex md:items-center md:justify-center">
              <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                <div className="text-base leading-6 font-medium text-gray-200">
                  Frank P.
                </div>
              </div>
            </div>
          </footer>
        </div>
        <div className="sm:block sm:max-w-screen-xl mx-auto mt-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto text-left sm:text-center text-2xl leading-7 text-gray-200">
            <p>
              “My macbook was running slow and I called Nelson to see what some
              options were. He came out to my home office free of charge to take
              a look. A new hard drive was needed and Nelson gave me a great
              recommendation of what exactly to get. After I purchased this
              drive, he returned to my house at 11 am on a Friday and by 6 pm,
              he was knocking on my door with my updated laptop. You just can’t
              get this level of service at any of the local computer repair
              stores. Trust me, I went to two of them and was looking at a 1 to
              2 week period where they would have my laptop sitting on a shelf
              for a 2-hour repair. I can’t be without my computer for 2 days let
              alone 2 weeks! I was also impressed by how affordable Nelson’s
              services were. I highly recommend Nelson’s services.”
            </p>
          </div>
          <footer className="mt-2">
            <div className="md:flex md:items-center md:justify-center">
              <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                <div className="text-base leading-6 font-medium text-gray-200">
                  Ryan J., Exeter, NH
                </div>
              </div>
            </div>
          </footer>
        </div>
        <div className="sm:block sm:max-w-screen-xl mx-auto mt-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto text-left sm:text-center text-2xl leading-7 text-gray-200">
            <p>
              “Nelson Ashberger is just excellent in service, reliability,
              price, expertise, and customer relations. On a recent Saturday
              morning, my computer was unresponsive. It would not boot despite
              the many troubleshooting tips I attempted. Documents on it needed
              to be filed Monday morning and I had no hard copies. Nelson was a
              lifesaver! Although my hard drive was gasping its last breath, he
              worked through the weekend and retrieved my data. He also helped
              me select a new computer, advising me on all aspects of making
              this decision. He set it up, configuring and transferring my data
              to it, thus saving me time and eliminating any stress related to
              such a task. His skills, positive attitude and friendly
              personality make Nelson my go-to computer guy. I highly recommend
              his services.”
            </p>
          </div>
          <footer className="mt-2">
            <div className="md:flex md:items-center md:justify-center">
              <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                <div className="text-base leading-6 font-medium text-gray-200">
                  Donna S.
                </div>
              </div>
            </div>
          </footer>
        </div>
        <div className="sm:block sm:max-w-screen-xl mx-auto mt-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto text-left sm:text-center text-2xl leading-7 text-gray-200">
            <p>
              “Nelson is a friend of ours and has often helped us with our
              computers. (We are an all Apple projects household, but he has
              worked professionally with PCs.) He came over this past week and
              upgraded my MacBook Pro, installing RAM (it had 2 GB and now has
              4GB), cleaned the insides while it was open, updated the OS from
              10.5.8 to 10.6.8 (I’m not going higher for now because I’ll have
              to buy a new MS Office edition if I do, which he knew and thus
              saved me from making that mistake), then updated some of my
              software and my browser to match the higher OS, and generally gave
              me some advice and tips, for example about the Time Machine backup
              and full backup that I did before installing the new OS. He also
              helped my husband with backing up his older G4 desk model. Nelson
              owns and uses a Mac, so he’s great with them, but he’s always
              worked professionally with PCs, so he’s very qualified with
              those.”
            </p>
          </div>
          <footer className="mt-2">
            <div className="md:flex md:items-center md:justify-center">
              <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                <div className="text-base leading-6 font-medium text-gray-200">
                  Diane B.
                </div>
              </div>
            </div>
          </footer>
        </div>
      </Carousel>
    </section>
  )
}

export default Testimonials
