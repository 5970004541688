import React, { ReactElement } from "react"
import Header from "../components/header"
import Testimonials from "../components/testimonials"
import Services from "../components/services"
import Rates from "../components/rates"
import RemoteSupport from "../components/remoteSupport"
import Contact from "../components/contact"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"

function Index(): ReactElement {
  const data = useStaticQuery(graphql`
    query MyQuery {
      logo: file(relativePath: { eq: "nelsonscomputerlogo.jpg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }
      profile: file(relativePath: { eq: "nelsonprofile.jpg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }
      working: file(relativePath: { eq: "nelsonworking.jpg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)

  return (
    <div id="home">
      <SEO />
      <Header />
      <Img
        fluid={data.logo.childImageSharp.fluid}
        alt="Nelson's computer help logo sharing a helping hand."
      />
      <section className="bg-black pt-4 pb-4">
        <div className="sm:max-w-2xl sm:mx-auto text-xl leading-7 text-gray-200 lg:mx-auto">
          <div className="flex flex-row justify-center flex-wrap px-4 py-1 sm:p-6">
            <h2 className="text-2xl text-left">
              For all your computer repair and technology needs!
            </h2>
            <div className="w-full mt-4">
              I service all makes and models of PC computers including, but not
              limited to:
              <div className="flex justify-around mt-4">
                <ul>
                  <li>Acer</li>
                  <li>Apple</li>
                  <li>Asus</li>
                  <li>Dell</li>
                </ul>
                <ul>
                  <li>HP</li>
                  <li>Lenovo</li>
                  <li>Sony</li>
                  <li>Toshiba</li>
                </ul>
              </div>
              <div className="text-center mt-4">
                And any custom built computer
              </div>
            </div>
          </div>
          <div className="mt-4 px-4">
            <div className="float-right">
              <Img
                style={{ width: 120, height: 180, padding: 5 }}
                fluid={data.profile.childImageSharp.fluid}
                alt="A picture of Nelson himself, ready to help with your computer repair."
              />
            </div>
            <div className="leading-7">
              With over 19 years of Information Technology experience, I’m
              prepared to service a wide variety of client computer repair
              needs, including networking systems, wireless, computer hardware,
              servers, and software. I have worked in cyber risk assessment,
              principle administrator roles, desktop deployments, and setup new
              servers for clients. I have implemented Spiceworks and
              ManageEngine servers to audit and maintain networks for mid-size
              companies. Don’t want to spend an arm and a leg on a new computer?
              Refurbished units are a wonderful way to improve your processing
              power without breaking the bank.
            </div>
          </div>
          <div className="mt-4 px-4 leading-7">
            <div className="float-left">
              <Img
                style={{ width: 120, height: 180, margin: 5 }}
                fluid={data.working.childImageSharp.fluid}
                alt="Nelson is at work sovling your problems."
              />
            </div>
            <div>
              I am local, in Raymond, Epping, Kingston, Exeter and Brentwood,
              New Hampshire areas and have over 15 years of references in
              Southern New Hampshire and Southern Maine areas. I am reasonable,
              flexible and friendly. I provide personalized service and
              affordable rates, and offer on-site and off-site remote support
              with pickup and delivery.
            </div>
            <div className="pt-4">
              Whether your computer repair needs are at home, on the road, or in
              a corporate or small-business setting, I can help. Contact me to
              schedule an appointment.
            </div>
          </div>
        </div>
      </section>
      <Services />
      <Rates />
      <Testimonials />
      <RemoteSupport />
      <Contact />
      <div className="bg-black">
        <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
          <div className="flex justify-center md:order-2"></div>
          <div className="mt-8 md:mt-0 md:order-1">
            <p className="text-center text-base leading-6 text-gray-400">
              &copy; 2020 Nelson's Computer Help. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Index
