import React, { ReactElement, useState } from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"

function Header(): ReactElement {
  const [isMenuOpen, setMenuOpen] = useState(false)
  return (
    <nav className="bg-black top-0 sticky z-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="-ml-2 mr-2 flex items-center md:hidden">
              <button
                aria-label="Dropdown Menu"
                onClick={() => setMenuOpen(!isMenuOpen)}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white transition duration-150 ease-in-out"
              >
                <svg
                  className={`h-6 w-6 text-outline + ${
                    isMenuOpen ? "hidden" : "block"
                  }`}
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
                <svg
                  className={`h-6 w-6 ${isMenuOpen ? "block" : "hidden"}`}
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="hidden md:ml-6 md:flex md:items-center">
              <AnchorLink
                href="#home"
                offset=""
                className="px-3 py-2 rounded-md text-sm font-medium leading-5 text-white hover:text-white hover:bg-gray-700 focus:outline-none focus:text-green focus:bg-shadowgreen"
              >
                Nelson's Computer Help
              </AnchorLink>
              <AnchorLink
                href="#services"
                offset="50"
                className="ml-4 px-3 py-2 rounded-md text-sm font-medium leading-5 text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-green focus:bg-shadowgreen"
              >
                Services
              </AnchorLink>
              <AnchorLink
                href="#rates"
                offset="50"
                className="ml-4 px-3 py-2 rounded-md text-sm font-medium leading-5 text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-green focus:bg-shadowgreen transition duration-150 ease-in-out"
              >
                Rates
              </AnchorLink>
              <AnchorLink
                href="#testimonials"
                offset="50"
                className="ml-4 px-3 py-2 rounded-md text-sm font-medium leading-5 text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-green focus:bg-shadowgreen transition duration-150 ease-in-out"
              >
                Testimonials
              </AnchorLink>
              <AnchorLink
                href="#remotesupport"
                offset="50"
                className="ml-4 px-3 py-2 rounded-md text-sm font-medium leading-5 text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-green focus:bg-shadowgreen transition duration-150 ease-in-out"
              >
                Remote Support
              </AnchorLink>
            </div>
          </div>
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <span className="rounded-md shadow-sm">
                <AnchorLink href="#contact" offset="110">
                  <button
                    type="button"
                    className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-green hover:bg-shadowgreen hover:text-green focus:outline-none focus:text-black focus:bg-shadowgreen focus:shadow-outline-outline focus:border-outline active:bg-outline transition duration-150 ease-in-out"
                  >
                    Contact Nelson
                  </button>
                </AnchorLink>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={`md:hidden + ${isMenuOpen ? "block" : "hidden"}`}>
        <div className="px-2 pt-2 pb-3 sm:px-3">
          <AnchorLink
            href="#home"
            onClick={() => setMenuOpen(!isMenuOpen)}
            className="block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:text-green focus:bg-shadowgreen transition duration-150 ease-in-out"
          >
            Nelson's Computer Help
          </AnchorLink>
          <AnchorLink
            href="#services"
            offset="300"
            onClick={() => setMenuOpen(!isMenuOpen)}
            className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white transition duration-150 ease-in-out"
          >
            Services
          </AnchorLink>
          <AnchorLink
            href="#rates"
            offset="290"
            onClick={() => setMenuOpen(!isMenuOpen)}
            className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
          >
            Rates
          </AnchorLink>
          <AnchorLink
            href="#testimonials"
            offset="275"
            onClick={() => setMenuOpen(!isMenuOpen)}
            className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
          >
            Testimonials
          </AnchorLink>
          <AnchorLink
            href="#remotesupport"
            offset="295"
            onClick={() => setMenuOpen(!isMenuOpen)}
            className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
          >
            Remote Support
          </AnchorLink>
        </div>
      </div>
    </nav>
  )
}

export default Header
