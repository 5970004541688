import React, { ReactElement } from "react"
// @ts-ignore
import Updates from "../svgs/updates.svg"
// @ts-ignore
import Maintenance from "../svgs/maintenance.svg"
// @ts-ignore
import Bugfixing from "../svgs/bugfixing.svg"

function Services(): ReactElement {
  return (
    <section id="services" className="pt-4 pb-4 bg-green">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-100 sm:text-4xl sm:leading-10">
            Services
          </h3>
          <p className="mt-4 max-w-2xl text-xl leading-7 text-gray-200 lg:mx-auto">
            I offer a wide array of computer repair services and expert advice.
            I can come to your home or remote in to your computer to help you
            with most any issue you may have. I am committed to getting your
            system back up, good as new. Did you know many computer problems can
            be resolved in one or two hours or less? Here are a few of the many
            support options I can offer you.
          </p>
        </div>
        <div className="pt-4 bg-green">
          <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-screen-xl lg:px-8">
            <div className="lg:grid lg:grid-cols-3 lg:gap-8">
              <div>
                <div className="mt-10">
                  <Bugfixing className="mx-auto w-56 h-48 pb-4" />
                  <h5 className="leading-6 text-2xl text-gray-100">
                    Hardware/Software Repair & Troubleshooting
                  </h5>
                  <ul className="list-disc mt-2 text-xl leading-7 text-gray-200">
                    <li>Malicious Software, Virus & Spyware Removal</li>
                    <li>Computer optimization & cleanup</li>
                    <li>Data Recovery and Virtualization</li>
                  </ul>
                </div>
              </div>
              <div className="mt-10 lg:mt-0">
                <div className="sm:mt-10">
                  <Maintenance className="mx-auto w-32 h-48" />
                  <h5 className="text-2xl leading-6 text-gray-100">
                    PC Hardware Upgrades and System Building
                  </h5>
                  <ul className="list-disc mt-2 text-xl leading-7 text-gray-200">
                    <li>
                      Complete custom system builds from scratch, I help you
                      select the components and I build you a computer! This
                      includes gaming, CAD or business workstation, and simple
                      web browsing machines. In the event you don’t want to
                      build a custom system there are many options from multiple
                      manufacturers I can recommend, such as Apple, Lenovo, and
                      HP to name a few! I am committed to helping you get the
                      most computer out of your money!
                    </li>
                    <li>Memory Upgrades</li>
                    <li>
                      Hard Disk/Solid State Drives and DVD-RW Optical Drive
                      Upgrades
                    </li>
                    <li>Graphics Card Upgrades</li>
                  </ul>
                </div>
              </div>
              <div className="mt-10 lg:mt-0">
                <div className="sm:mt-10">
                  <Updates className="mx-auto w-40 h-48" />
                  <h5 className="text-2xl leading-6 font-medium text-gray-100">
                    Computer Software Updates and Installs
                  </h5>

                  <ul className="list-disc mt-4 text-xl leading-7 text-gray-200">
                    <li>Data Transfer from PC to PC</li>
                    <li>
                      Complete Operating System Install/Upgrade (Windows XP, 7,
                      Mac OS X)
                    </li>
                    <li>
                      Software Install (Non-OS)(MS Office, Antivirus and the
                      like)
                    </li>
                    <li>Setting up a backup of your PC or Mac</li>
                    <li>Data Recovery and restoration of lost files</li>
                    <li>Cable Modem Setup</li>
                    <li>Scanner / Printer Setup</li>
                    <li>Home Network & Secure Wireless Setup/Lockdown</li>
                    <li>Free Dust removal and wipe down.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Services
