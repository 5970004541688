import React, { ReactElement } from "react"
import ReCAPTCHA from "react-google-recaptcha"

function Contact(): ReactElement {
  return (
    <div className="bg-green text-gray-200 pt-4 pb-4 px-4">
      <h3 className="mt-2 sm:max-w-2xl sm:mx-auto sm:text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-100 sm:text-4xl sm:leading-10">
        Contact
      </h3>
      <form
        className="mt-2 text-left mx-auto sm:max-w-2xl sm:mx-auto"
        name="nelsons-contact"
        action="./success"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        id="contact"
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="nelsons-contact" />
        <p hidden>
          <label>
            Do not use this, for bytes only: <input name="bot-field" />
          </label>
        </p>
        <label
          htmlFor="name"
          className="block text-xl mt-2 mb-1 sm:text:3xl font-medium leading-6 text-gray-200"
        >
          Your Name
        </label>
        <input
          className="w-full rounded text-black px-2"
          id="name"
          type="text"
          name="Name"
        />

        <label
          htmlFor="email"
          className="block text-xl mt-2 mb-1 sm:text:3xl font-medium leading-6 text-gray-200"
        >
          Your Email
        </label>
        <input
          className="w-full rounded text-black px-2"
          id="email"
          type="text"
          name="Email"
        />

        <label
          htmlFor="phonenumber"
          className="block text-xl mt-2 mb-1 sm:text:3xl font-medium leading-6 text-gray-200"
        >
          Your Phone Number
        </label>
        <input
          className="w-full rounded text-black px-2"
          id="phonenumber"
          type="text"
          name="Phone number"
        />

        <label
          htmlFor="subjectline"
          className="block text-xl mt-2 mb-1 sm:text:3xl font-medium leading-6 text-gray-200"
        >
          Subject
        </label>
        <input
          className="w-full rounded text-black px-2"
          id="subjectline"
          type="text"
          name="Subject line"
        />

        <label
          htmlFor="message"
          className="block text-xl mt-2 mb-1 sm:text:3xl font-medium leading-5 text-gray-200"
        >
          Your Message
        </label>
        <textarea
          id="message"
          className="w-full rounded text-black px-2"
          name="Message"
        />
        <div>
          <ReCAPTCHA sitekey={process.env.GATSBY_RECAPTCHA_KEY} />
        </div>
        <span className="block text-xl mt-2 sm:text:3xl rounded-md shadow-sm">
          <button
            type="submit"
            value="send message"
            className="inline-flex items-center text-green px-6 py-4 border border-gray-300 text-xl leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
          >
            Send
          </button>
        </span>
      </form>
    </div>
  )
}

export default Contact
